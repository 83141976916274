export const states = [
  {
    name: 'New South Wales',
    abbreviation: 'NSW',
  },
  {
    name: 'Victoria',
    abbreviation: 'VIC',
  },
  {
    name: 'Queensland',
    abbreviation: 'QLD',
  },
  {
    name: 'Tasmania',
    abbreviation: 'TAS',
  },
  {
    name: 'South Australia',
    abbreviation: 'SA',
  },
  {
    name: 'Western Australia',
    abbreviation: 'WA',
  },
  {
    name: 'Northern Territory',
    abbreviation: 'NT',
  },
  {
    name: 'Australian Capital Territory',
    abbreviation: 'ACT',
  },
  {
    name: '[none]',
    abbreviation: 'N/A',
  },
]

export function fullStateToAbbreviation(fullState?: string) {
  return (
    states.find((state) => state.name === fullState)?.abbreviation ?? fullState
  )
}

export function abbreviationToFullState(abbreviation?: string) {
  if (abbreviation === 'N/A') {
    return abbreviation
  }
  return (
    states.find((state) => state.abbreviation === abbreviation)?.name ??
    abbreviation
  )
}
